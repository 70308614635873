import mavisCollections from './mavis/collections'
export type CollectionGroup =
  | 'characters'
  | 'relics'
  | 'game'
  | 'community'
  | 'cards'

export type CollectionDefinitionType = {
  contract: string
  name: string
  group: CollectionGroup
  isPixelated: boolean
  isOfficial: boolean
  aspectRatio?: string
  isCards?: boolean
  walkcycleImageUrlTemplate?: string
  getUrlForBuy?: (tokenId: string | number) => string
  chain?: string
}

const CollectionGroups: { [key: string]: CollectionGroup } = {
  characters: 'characters',
  relics: 'relics',
  game: 'game',
  community: 'community',
  cards: 'cards',
}

export const CollectionQueue: CollectionGroup[] = [
  CollectionGroups.characters,
  CollectionGroups.game,
  CollectionGroups.relics,
  CollectionGroups.cards,
  CollectionGroups.community,
]

export const hideFromHomepage: string[] = [
  '0xa3abf5552cc5e1477009bbf90d0b8d2689883891', // Items
  '0x5d4aa6ff9de7963ead5a17b454dc1093ca9e98e7', // Rings
]

export const contractDefinition: CollectionDefinitionType[] = [
  {
    contract: '0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42',
    name: 'Wizards',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
    walkcycleImageUrlTemplate:
      'https://runes-turnarounds.s3.amazonaws.com/:tokenId/:tokenId-walkcycle-nobg.gif',
  },
  {
    contract: '0x251b5f14a825c537ff788604ea1b58e49b70726f',
    name: 'Souls',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
    walkcycleImageUrlTemplate:
      'https://runes-turnarounds.s3.amazonaws.com/souls/:tokenId/:tokenId-walkcycle-nobg.gif',
  },
  {
    contract: '0xf55b615b479482440135ebf1b907fd4c37ed9420',
    name: 'Ponies',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
    walkcycleImageUrlTemplate:
      'https://runes-turnarounds.s3.amazonaws.com/ponies/:tokenId.gif',
  },
  {
    contract: '0x8634C23D5794Ed177E9Ffd55b22fdB80A505ab7B',
    name: 'Beasts',
    group: CollectionGroups.characters,
    isPixelated: false,
    isOfficial: true,
  },
  {
    contract: '0x7de11a2d9E9727fa5eAd3094E40211C5e9cf5857',
    name: 'Spawn',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
  },
  {
    contract: '0x9690b63Eb85467BE5267A3603f770589Ab12Dc95',
    name: 'Warriors',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
    walkcycleImageUrlTemplate:
      'https://runes-turnarounds.s3.amazonaws.com/warriors/:tokenId/:tokenId-walkcycle-nobg.gif',
  },
  {
    contract: '0x7c104b4db94494688027cced1e2ebfb89642c80f',
    name: 'Athenaeum',
    group: CollectionGroups.relics,
    isPixelated: true,
    isOfficial: true,
  },
  {
    contract: '0xda5cf3a42ebacd2d8fcb53830b1025e01d37832d',
    name: 'Locks',
    group: CollectionGroups.relics,
    isPixelated: false,
    isOfficial: true,
  },
  {
    contract: '0x31158181b4b91a423bfdc758fc3bf8735711f9c5',
    name: 'Flames',
    group: CollectionGroups.relics,
    isPixelated: false,
    isOfficial: true,
  },
  {
    contract: '0x59775fd5f266c216d7566eb216153ab8863c9c84',
    name: 'Boxes',
    group: CollectionGroups.relics,
    isPixelated: false,
    isOfficial: true,
  },
  {
    contract: '0x5d4aa6ff9de7963ead5a17b454dc1093ca9e98e7',
    name: 'Rings',
    group: CollectionGroups.game,
    isPixelated: false,
    isOfficial: true,
  },
  {
    contract: '0x5a79182165a2917ef9cccf33f472fe22afffeff8',
    name: 'Nouns',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0x4b1e130ae84c97b931ffbe91ead6b1da16993d45',
    name: 'Babies',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0x4bce2bf108599257f84e0b1965631132a579481b',
    name: 'Cucumberland',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0x4addac15971ab60ead954b8f15a67518730450e0',
    name: 'Punks',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0x4715be0c5e9bcfe1382da60cff69096af4c4eef4',
    name: 'Barron Court',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0xf486f696b80164b5943191236eca114f4efab2ff',
    name: 'Blacksands Mounts',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0xa3abf5552cc5e1477009bbf90d0b8d2689883891',
    name: 'Runiverse items',
    group: CollectionGroups.game,
    isPixelated: false,
    isOfficial: true,
  },
  {
    contract: '0x5af7678c2af78497c48340e42e34af4546440e0d',
    name: 'Runes TCG',
    group: CollectionGroups.cards,
    isPixelated: true,
    aspectRatio: '512/672',
    isCards: true,
    isOfficial: false,
  },
  // {
  //   contract: mavisCollections.cyberKongz,
  //   name: 'CyberKongz',
  //   group: CollectionGroups.game,
  //   isPixelated: false,
  // },
  // {
  //   contract: mavisCollections.cyberKongz,
  //   name: 'CyberKongz',
  //   group: CollectionGroups.game,
  //   isPixelated: false,
  // },
  // shared storefront
  // {
  //   contract: '0x495f947276749ce646f68ac8c248420045cb7b5e',
  //   name: 'Tiny Cult Pixel Dolls',
  //   group: CollectionGroups.community,
  //   isPixelated: true,
  // },
  {
    contract: mavisCollections.runiverseLand,
    name: 'Runiverse Land',
    group: CollectionGroups.game,
    isPixelated: true,
    isOfficial: true,
    chain: 'ronin',
    getUrlForBuy: (tokenId) =>
      `https://marketplace.skymavis.com/collections/forgotten-runiverse-real-estate/${tokenId}`,
  },
  {
    contract: mavisCollections.rings,
    name: 'Rings',
    group: CollectionGroups.game,
    isPixelated: true,
    isOfficial: true,
    getUrlForBuy: (tokenId) =>
      `https://marketplace.skymavis.com/collections/forgotten-runiverse-watchers-rings/${tokenId}`,
  },
  {
    contract: mavisCollections.runiverseItems,
    name: 'Runiverse Items',
    group: CollectionGroups.game,
    isPixelated: true,
    isOfficial: true,
    getUrlForBuy: (tokenId) =>
      `https://marketplace.skymavis.com/collections/forgotten-runiverse-items/${tokenId}`,
  },

  {
    contract: '0x1568d960941cdd203844661161bfe38253d39aa6',
    name: 'Soul Punks',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0xaee9f5908e70a749e9289d61cd37757e62bed50a',
    name: "The Barren Court: Komodo's Roost",
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: '0x26d89b1a97eff04f0084bfddd5c53fe7c869d344',
    name: 'Forgotten Smouls',
    group: CollectionGroups.community,
    isPixelated: true,
    isOfficial: false,
  },
  {
    contract: 'shadows',
    name: 'Shadows',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
    getUrlForBuy: (tokenId) =>
      `https://magiceden.io/ordinals/item-details/${tokenId}`,
  },
  {
    contract: 'shadowhats',
    name: 'Shadow Hats',
    group: CollectionGroups.characters,
    isPixelated: true,
    isOfficial: true,
    getUrlForBuy: (tokenId) =>
      `https://magiceden.io/ordinals/item-details/${tokenId}`,
  },
]

export const getContractDefinition = (collectionId: string) =>
  contractDefinition.find(
    ({ contract }) => contract.toLowerCase() === collectionId?.toLowerCase()
  ) as CollectionDefinitionType
